import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
import EmailCourseSignup from '../components/EmailCourseSignup/EmailCourseSignupForm';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="text-center text-3xl leading-normal uppercase text-gray-800">Transition to Serverless</h1>
    <h2 className="text-center mb-8">A free 5-day email course to help you understand the why, what and how of adopting serverless on AWS within your team</h2>
    <p>{`Adopting serverless within a company is much more than a technology replacement, it’s a mindset shift. Learn how to navigate the roadblocks on your journey with this short email course.`}</p>
    <EmailCourseSignup mdxType="EmailCourseSignup" />
    <h3>{`What’s the lesson plan?`}</h3>
    <p>{`Over the course of 5 days, you’ll learn:`}</p>
    <ul>
      <li parentName="ul">{`Lesson 1: Why serverless is inevitable`}</li>
      <li parentName="ul">{`Lesson 2: How to identify a candidate project for your first serverless application`}</li>
      <li parentName="ul">{`Lesson 3: How to compose the building blocks that AWS provides`}</li>
      <li parentName="ul">{`Lesson 4: Common mistakes to avoid when building your first serverless application`}</li>
      <li parentName="ul">{`Lesson 5: How to break ground on your first serverless project`}</li>
    </ul>
    <h3>{`Who’s teaching the course?`}</h3>
    <p>{`Hi, I’m Paul Swail.`}</p>
    <p>{`I’m an independent cloud architect who specialises in guiding development teams ship quality serverless applications into production. I also `}<a parentName="p" {...{
        "href": "/articles/"
      }}>{`write frequently on serverless topics`}</a>{`.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "/about/"
      }}>{`read more about me here`}</a>{`, `}<a parentName="p" {...{
        "href": "https://notes.paulswail.com"
      }}>{`dip into my public working notes here`}</a>{` or `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`get in contact here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      